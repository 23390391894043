
import { Component, Prop, Vue } from "vue-property-decorator";

type Tile = {
  name: string;
};

@Component
export default class DocumentsComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  documentIcon(extension: string) {
    let icon = "mdi-file-outline";

    if (extension.match(/jpg|jpeg|png/g) != null) icon = "mdi-file-image-outline";
    if (extension.match(/pdf/g) != null) icon = "mdi-file-pdf-outline";

    return icon;
  }
}
